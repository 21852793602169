import { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import MyInputText from "../form/MyInputText";
import { useTodoList } from "../states/list";
import { useAddTaskSlider } from "../states/ui";
import { confirmDialog } from "primereact/confirmdialog";
import { useTranslation } from "react-i18next";

const TaskAddSidebar = () => {
  const { t } = useTranslation();
  const API_ENDPOINT = import.meta.env.VITE_REACT_APP_API_ENDPOINT
  const visible = useAddTaskSlider((state) => state.visible);
  const setVisible = useAddTaskSlider((state) => state.setVisible);
  const addTask = useTodoList((state) => state.addTask);
  const taskEdit = useTodoList((state) => state.taskEdit);
  const setTaskEdit = useTodoList((state) => state.setTaskEdit);
  const editTask = useTodoList((state) => state.editTask);
  const removeTask = useTodoList((state) => state.removeTask);

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [dueTime, setDueTime] = useState(null);
  const [priority, setPriority] = useState(null);
  const [errors, setErrors] = useState({});
  const currentList = useTodoList((state) => state.currentList);

  useEffect(() => {
    if (taskEdit && Object.keys(taskEdit).length !== 0) {
      setTitle(taskEdit.title);
      if (taskEdit.due_time) {
        setDueTime(new Date(taskEdit.due_time));
      }
      setPriority(taskEdit.priority);
    }
  }, [taskEdit]);

  const createTask = async (task) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_ENDPOINT}/api/todo/tasks/${
          taskEdit.id ? taskEdit.id + "/" : ""
        }`,
        {
          method: taskEdit.id ? "PATCH" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify(task),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setErrors(errorData);
      } else {
        const taskData = await response.json();
        if (taskEdit.id) {
          editTask(taskData);
        } else {
          addTask(taskData);
        }
        resetForm();
        setVisible(false);
      }
    } catch (error) {
      setErrors({ error: [t("error_creating_task")] });
      console.error("Error creating task:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteTask = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_ENDPOINT}/api/todo/tasks/${taskEdit.id}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.ok) {
        removeTask(taskEdit.id);
        resetForm();
        setVisible(false);
      } else {
        const errorData = await response.json();
        setErrors(errorData);
      }
    } catch (error) {
      setErrors({ error: [t("error_deleting_task")] });
      console.error("Error deleting task:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setTitle("");
    setDueTime(null);
    setPriority(null);
    setErrors({});
    setTaskEdit({});
  };

  const load = () => {
    createTask({ title, due_time: dueTime, priority, list: currentList.id });
  };

  const customHeader = (
    <div className="flex align-items-center gap-2">
      <Avatar
        label={taskEdit.id ? "✏️" : "➕"}
        style={{ background: "none" }}
        shape="circle"
      />
      <span className="font-bold">
        {taskEdit.id ? t("edit_task") : t("create_task")}
      </span>
    </div>
  );

  const priorityOptions = [
    { label: t("p1"), value: "P1" },
    { label: t("p2"), value: "P2" },
    { label: t("p3"), value: "P3" },
    { label: t("p4"), value: "P4" },
  ];

  const getPriorityColor = (p) => {
    switch (p) {
      case "P1":
        return "text-red-700";
      case "P2":
        return "text-yellow-600";
      case "P3":
        return "text-blue-500";
      default:
        return;
    }
  };

  const selectedPriorityTemplate = (option) => {
    if (!option) {
      option = { label: t("no_priority"), value: "" };
    }
    return (
      <div
        className={`flex align-items-center ${getPriorityColor(option.value)}`}
      >
        <i className="pi pi-flag mr-2"></i>
        <div>{option.label}</div>
      </div>
    );
  };

  const priorityOptionTemplate = (option) => (
    <div
      className={`flex align-items-center ${getPriorityColor(option.value)}`}
    >
      <i className={`pi pi-flag mr-2 ${getPriorityColor(option.value)}`}></i>
      <div>{option.label}</div>
    </div>
  );

  return (
    <Sidebar
      header={customHeader}
      visible={visible}
      onHide={() => {
        resetForm();
        setVisible(false);
      }}
      position="top"
      dismissable={false}
      style={{ height: "auto" }}
    >
      <div className="flex flex-column gap-2">
        <MyInputText
          id="title"
          value={title}
          errors={errors}
          placeholder={t("task_title_placeholder")}
          onInput={(e) => setTitle(e.target.value)}
        />

        <Calendar
          value={dueTime}
          onChange={(e) => setDueTime(e.value)}
          dateFormat="yy-mm-dd"
          className="flex-grow-1"
          placeholder={t("select_due_time")}
          showButtonBar
          touchUI
          showIcon
        />

        <Dropdown
          value={priority}
          options={priorityOptions}
          onChange={(e) => setPriority(e.value)}
          className="flex-grow-1"
          placeholder={t("no_priority")}
          valueTemplate={selectedPriorityTemplate}
          itemTemplate={priorityOptionTemplate}
          emptyMessage={selectedPriorityTemplate}
          showClear
        />
      </div>
      <div className="flex">
        <Button
          label={taskEdit.id ? t("edit") : t("create")}
          icon={`pi pi-${taskEdit.id ? "pencil" : "plus"}`}
          loading={loading}
          onClick={load}
          className="mt-2"
        />

        {taskEdit.id && (
          <Button
            label={t("delete")}
            icon="pi pi-trash"
            loading={loading}
            onClick={() =>
              confirmDialog({
                message: t("task_delete_confirmation_message"),
                header: t("task_delete_confirmation_header"),
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                acceptLabel: t("yes"),
                rejectLabel: t("no"),
                accept: deleteTask,
              })
            }
            severity="danger"
            className="ml-auto mt-2"
          />
        )}
      </div>
    </Sidebar>
  );
};

export default TaskAddSidebar;
