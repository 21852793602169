import { PrimeReactProvider } from "primereact/api";
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import 'primereact/resources/themes/lara-light-blue/theme.css'
import './components/translation/i18n.js';

ReactDOM.createRoot(document.getElementById('app')).render(
  <PrimeReactProvider>
    <App />
  </PrimeReactProvider>,
)
