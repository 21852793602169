import { Sidebar } from "primereact/sidebar";
import { Menu } from "primereact/menu";
import logo from "../assets/logo.jpg";
import { useAddListSlider, useListSlider } from "./states/ui";
import { useTodoList } from "./states/list";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import i18n from "./translation/i18n";
import { useTranslation } from "react-i18next";

const SidebarMenu = () => {
  const { t } = useTranslation();
  const isListVisible = useListSlider((state) => state.visible);
  const setListVisible = useListSlider((state) => state.setVisible);
  const showAddListSlider = useAddListSlider((state) => state.setVisible);
  const lists = useTodoList((state) => state.list);
  const setListToEdit = useTodoList((state) => state.setListEdit);
  const setCurrentList = useTodoList((state) => state.setCurrentList);

  const handleTaskSelection = (item) => {
    const selectedList = lists.find((list) => list.id === item.key);
    setCurrentList(selectedList);
    setListVisible(false);
  };

  const renderMenuItem = (item) => (
    <div className="flex p-menuitem-content align-items-center">
      <a
        className="align-items-center p-menuitem-link flex-grow-1"
        onClick={() => handleTaskSelection(item)}
      >
        <span>{item.icon}</span>
        <span className="flex-grow-1 mx-2">{item.label}</span>
      </a>
      <i
        className="pi pi-pencil align-items-right text-sm text-400 align-items-center px-2 py-2 mr-3"
        onClick={() => {
          showAddListSlider(true);
          setListToEdit(item.element);
        }}
      />
    </div>
  );

  const menuItems = [
    {
      template: () => (
        <span className="inline-flex align-items-center gap-1 px-1">
          <img src={logo} width="70" height="70" alt="Logo" />
          <span className="font-medium text-2xl font-semibold">
            DO<span className="text-primary">DOLIST</span>
          </span>
        </span>
      ),
    },
    { separator: true },
    {
      template: () => (
        <div className="p-submenu-header" onClick={() => showAddListSlider(true)}>
          <a className="flex align-items-center p-menuitem-link">
            <span className="flex-grow-1">{t('list')}</span>
            <Button
              style={{ height: 2, width: 30 }}
              icon="pi pi-plus"
              text
              outlined
            />
          </a>
        </div>
      ),
    },
    ...lists.map((element) => ({
      key: element.id,
      label: element.name,
      icon: element.emoji,
      element,
      template: renderMenuItem,
    })),
  ];

  const languages = [
    { name: "English", flag: "US", code: "en" },
    { name: "Deutsch", flag: "DE", code: "de" },
    { name: "Bahasa Indonesia", flag: "ID", code: "id" },
    { name: "Русский", flag: "RU", code: "ru" },
    { name: "Español", flag: "ES", code: "es" },
    { name: "Français", flag: "FR", code: "fr" },
    { name: "Italiano", flag: "IT", code: "it" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find((lang) => lang.code === (localStorage.getItem("lng") || "en"))
  );

  const renderSelectedLanguageTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
            className={`mr-2 flag flag-${option.flag.toLowerCase()}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const renderLanguageOptionTemplate = (option) => (
    <div className="flex align-items-center">
      <img
        alt={option.name}
        src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
        className={`mr-2 flag flag-${option.flag.toLowerCase()}`}
        style={{ width: "18px" }}
      />
      <div>{option.name}</div>
    </div>
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Sidebar
      visible={isListVisible}
      onHide={() => setListVisible(false)}
      showCloseIcon={false}
      className="dodolist-sidebar"
    >
      <div className="w-full h-full flex flex-column">
        <Menu model={menuItems} className="w-full flex-grow-1 border-none" />
        <div className="flex  gap-1 mx-2 mb-4">
          <Dropdown
            value={selectedLanguage}
            onChange={(e) => {
              setSelectedLanguage(e.value);
              changeLanguage(e.value.code);
              localStorage.setItem("lng", e.value.code);
            }}
            scrollHeight="350px"
            options={languages}
            optionLabel="name"
            valueTemplate={renderSelectedLanguageTemplate}
            itemTemplate={renderLanguageOptionTemplate}
          />
          <div className="flex-grow-1"></div>
          <Button label="💡" rounded text severity="warning" aria-label="Notification" onClick={()=> window.open("https://siyanew.featurebase.app/", "_blank")} />
        </div>
      </div>
    </Sidebar>
  );
};

export default SidebarMenu;
