export const IndonesianTranslation = {
    // Lists
    list: "Daftar",
    list_exists: "Daftar sudah ada",
    error_creating_list: "Kesalahan saat membuat daftar",
    error_deleting_list: "Kesalahan saat menghapus daftar",
    edit_list: "Edit Daftar",
    create_list: "Buat Daftar",
    list_delete_confirmation_message: "Apakah Anda ingin menghapus daftar ini beserta semua tugas?",
    list_delete_confirmation_header: "Hapus Daftar",
  
    // Tasks
    task_title_placeholder: "Judul Tugas",
    select_due_time: "Pilih Waktu Jatuh Tempo",
    no_priority: "Tanpa Prioritas",
    edit_task: "Edit Tugas",
    create_task: "Buat Tugas",
    task_delete_confirmation_message: "Apakah Anda yakin ingin menghapus tugas ini?",
    task_delete_confirmation_header: "Konfirmasi",
  
    // Priorities
    p1: "P1 - Prioritas Tinggi",
    p2: "P2 - Prioritas Sedang",
    p3: "P3 - Prioritas Rendah",
    p4: "P4 - Prioritas Ditunda",
  
    // Actions
    edit: "Edit",
    create: "Buat",
    delete: "Hapus",
    yes: "Ya",
    no: "Tidak",
  
    // General
    loading: "Memuat...",
    no_tasks: "Tidak ada tugas di sini",
    "This field may not be blank.": "Kolom ini tidak boleh kosong."
  };
  