import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTodoList } from "./states/list";
import TaskItem from "./TaskItem";
import { Message } from "primereact/message";
import { Skeleton } from "primereact/skeleton";
import { useTranslation } from 'react-i18next';

const TaskList = () => {
  const { t } = useTranslation();
  const API_ENDPOINT = import.meta.env.VITE_REACT_APP_API_ENDPOINT

  const tasks = useTodoList((state) => state.currentTasks);
  const setTasks = useTodoList((state) => state.setCurrentTasks);
  const appendTasks = useTodoList((state) => state.appendTask);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const currentList = useTodoList((state) => state.currentList);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (currentList.id) {
      setPage(1);
      setTasks([]);
    }
  }, [currentList.id, setTasks]);

  useEffect(() => {
    const fetchTasks = async () => {
      if (!currentList?.id) return;

      if (page === 1) setLoaded(false);

      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${API_ENDPOINT}/api/todo/tasks/?list=${currentList.id}&page=${page}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        if (page === 1) {
          setTasks(data.results);
          setLoaded(true);
        } else {
          appendTasks(data.results);
        }

        setHasMore(data.next !== null);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    if (currentList.id) {
      fetchTasks();
    }
  }, [currentList.id, page]);

  const fetchMoreData = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return tasks.length > 0 ? (
    <InfiniteScroll
      dataLength={tasks.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<Message severity="info" text={t('loading')} />}
      className="w-full"
    >
      <div className="m-2">
        {tasks.map((task) => (
          <TaskItem key={task.id} task={task} />
        ))}
      </div>
    </InfiniteScroll>
  ) : (
    loaded ? (
      <div className="mt-5 text-center">
        <Message severity="error" text={t('no_tasks')} />
      </div>
    ) : (
      <div className="m-2">
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
        <Skeleton height="3rem" className="mb-2" />
      </div>
    )
  );
};

export default TaskList;
