import { useTodoList } from "./states/list";
import { useAddTaskSlider, useListSlider } from "./states/ui";

const Navbar = () => {
  const showListSidebar = useListSlider((state) => state.setVisible);
  const currentList = useTodoList((state) => state.currentList);
  const showAddTaskSlider = useAddTaskSlider((state) => state.setVisible);

  return (
    <div className="flex sticky top-0 z-2 w-full px-3 py-3 surface-0 shadow-1 text-800 font-semibold">
      <span id="reward" />
      <i className="pi pi-bars flex align-items-center pr-3" onClick={() => showListSidebar(true)}></i>
      <div className="flex align-items-center flex-grow-1" onClick={() => showListSidebar(true)}>
        <span className="text-xl">{currentList.emoji}</span>
        <span className="text-lg ml-2">{currentList.name}</span>
      </div>
      <i className="pi pi-plus mt-1 pl-5" onClick={() => showAddTaskSlider(true)}></i>
    </div>
  );
};

export default Navbar;