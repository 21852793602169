import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translation modules
import { EnglishTranslation } from "./en";
import { GermanTranslation } from "./de";
import { IndonesianTranslation } from "./id";
import { RussianTranslation } from "./ru";
import { SpanishTranslation } from "./es";
import { FrenchTranslation } from "./fr";
import { ItalianTranslation } from "./it";

// The translations
const resources = {
  en: {
    translation: EnglishTranslation,
  },
  de: {
    translation: GermanTranslation,
  },
  id: {
    translation: IndonesianTranslation,
  },
  ru: {
    translation: RussianTranslation,
  },
  es: {
    translation: SpanishTranslation,
  },
  fr: {
    translation: FrenchTranslation,
  },
  it: {
    translation: ItalianTranslation,
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("lng") || "en", // language to use
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
