import { create } from 'zustand'

export const useAddListSlider = create((set) => ({
  visible: false,
  setVisible: (v) => set(() => ({ visible: v })),
}))

export const useListSlider = create((set) => ({
  visible: false,
  setVisible: (v) => set(() => ({ visible: v })),
}))

export const useAddTaskSlider = create((set) => ({
  visible: false,
  setVisible: (v) => set(() => ({ visible: v })),
}))