import { useAddListSlider } from "../states/ui";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useTodoList } from "../states/list";
import MyInputText from "../form/MyInputText";
import { confirmDialog } from "primereact/confirmdialog";
import { useTranslation } from 'react-i18next';

const ListAddSidebar = () => {
  const { t } = useTranslation();
  const API_ENDPOINT = import.meta.env.VITE_REACT_APP_API_ENDPOINT

  const isVisible = useAddListSlider((state) => state.visible);
  const setIsVisible = useAddListSlider((state) => state.setVisible);
  const addList = useTodoList((state) => state.addList);
  const removeList = useTodoList((state) => state.removeList);
  const listBeingEdited = useTodoList((state) => state.listEdit);
  const setListBeingEdited = useTodoList((state) => state.setListEdit);

  const [isLoading, setIsLoading] = useState(false);
  const [isEmojiPanelVisible, setIsEmojiPanelVisible] = useState(false);
  const [listName, setListName] = useState("");
  const [listEmoji, setListEmoji] = useState("📝");
  const [formErrors, setFormErrors] = useState({});

  const handleListCreation = async (name, emoji) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const url = `${API_ENDPOINT}/api/todo/lists/${listBeingEdited.id ? `${listBeingEdited.id}/` : ""}`;
      const method = listBeingEdited.id ? "PATCH" : "POST";

      const response = await fetch(url, {
        method,
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ name, emoji }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setFormErrors(errorData);
      } else {
        addList(await response.json());
        resetForm();
        setIsVisible(false);
      }
    } catch (error) {
      setFormErrors({ error: [t('error_creating_list')] });
      console.error("Error creating list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleListDeletion = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_ENDPOINT}/api/todo/lists/${listBeingEdited.id}/`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.ok) {
        removeList(listBeingEdited.id);
        resetForm();
        setIsVisible(false);
      } else {
        const errorData = await response.json();
        setFormErrors(errorData);
      }
    } catch (error) {
      setFormErrors({ error: [t('error_deleting_list')] });
      console.error("Error deleting list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setListBeingEdited({});
    setListName("");
    setListEmoji("📝");
    setFormErrors({});
  };

  const handleSubmit = () => {
    handleListCreation(listName, listEmoji);
  };

  useEffect(() => {
    if (listBeingEdited.id) {
      setListName(listBeingEdited.name);
      setListEmoji(listBeingEdited.emoji);
    }
  }, [listBeingEdited]);

  const confirmDeletion = () => {
    confirmDialog({
      message: t('list_delete_confirmation_message'),
      header: t('list_delete_confirmation_header'),
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      acceptLabel: t('yes'),
      rejectLabel: t('no'),
      accept: handleListDeletion,
    });
  };

  const headerContent = (
    <div className="flex align-items-center gap-2">
      <Avatar
        label={listBeingEdited.id ? "✏️" : "➕"}
        style={{ background: "none" }}
        shape="circle"
      />
      <span className="font-bold">
        {listBeingEdited.id ? t('edit_list') : t('create_list')}
      </span>
    </div>
  );

  return (
    <Sidebar
      header={headerContent}
      visible={isVisible}
      onHide={() => {
        resetForm();
        setIsVisible(false);
      }}
      position="top"
      dismissable={false}
      style={{ height: "auto" }}
    >
      <div className="flex gap-2 mb-2">
        <Avatar
          label={listEmoji}
          size="large"
          className="border-round-md border-1 border-300 bg-white"
          onClick={() => setIsEmojiPanelVisible(prev => !prev)}
        />
        <MyInputText
          id="name"
          value={listName}
          errors={formErrors}
          className="flex-grow-1"
          onInput={(e) => setListName(e.target.value)}
        />
      </div>
      {isEmojiPanelVisible && (
        <div className="mb-2">
          <Picker
            data={data}
            onEmojiSelect={(emoji) => {
              setListEmoji(emoji.native);
              setIsEmojiPanelVisible(false);
            }}
            emojiVersion="15"
            skinTonePosition="none"
            theme="light"
            previewPosition="none"
            maxFrequentRows="0"
            dynamicWidth={true}
            className="w-full"
          />
        </div>
      )}
      <div className="flex">
        <Button
          label={listBeingEdited.id ? t('edit') : t('create')}
          icon={`pi pi-${listBeingEdited.id ? "pencil" : "plus"}`}
          loading={isLoading}
          onClick={handleSubmit}
          className="mt-1"
        />
        {listBeingEdited.id && (
          <Button
            label={t('delete')}
            icon={"pi pi-trash"}
            loading={isLoading}
            onClick={confirmDeletion}
            severity="danger"
            className="ml-auto"
          />
        )}
      </div>
    </Sidebar>
  );
};

export default ListAddSidebar;
