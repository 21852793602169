export const GermanTranslation = {
    // Lists
    list: "Listen",
    list_exists: "Liste existiert",
    error_creating_list: "Fehler beim Erstellen der Liste",
    error_deleting_list: "Fehler beim Löschen der Liste",
    edit_list: "Liste bearbeiten",
    create_list: "Liste erstellen",
    list_delete_confirmation_message: "Möchten Sie diese Liste mit allen Aufgaben löschen?",
    list_delete_confirmation_header: "Liste löschen",
  
    // Tasks
    task_title_placeholder: "Aufgabentitel",
    select_due_time: "Fälligkeitszeit wählen",
    no_priority: "Keine Priorität",
    edit_task: "Aufgabe bearbeiten",
    create_task: "Aufgabe erstellen",
    task_delete_confirmation_message: "Sind Sie sicher, dass Sie diese Aufgabe löschen möchten?",
    task_delete_confirmation_header: "Aufgabe löschen",
  
    // Priorities
    p1: "P1 - Hohe Priorität",
    p2: "P2 - Mittlere Priorität",
    p3: "P3 - Niedrige Priorität",
    p4: "P4 - Verschobene Priorität",
  
    // Actions
    edit: "Bearbeiten",
    create: "Erstellen",
    delete: "Löschen",
    yes: "Ja",
    no: "Nein",
  
    // General
    loading: "Wird geladen...",
    no_tasks: "Hier sind keine Aufgaben",
    "This field may not be blank.": "Dieses Feld darf nicht leer sein."
  };
  