export const EnglishTranslation = {
  // Lists
  list: "Lists",
  list_exists: "List exists",
  error_creating_list: "Error creating list",
  error_deleting_list: "Error deleting list",
  edit_list: "Edit List",
  create_list: "Create List",
  list_delete_confirmation_message: "Do you want to delete this list with all tasks?",
  list_delete_confirmation_header: "Delete List",

  // Tasks
  task_title_placeholder: "Task Title",
  select_due_time: "Select Due Time",
  no_priority: "No Priority",
  edit_task: "Edit Task",
  create_task: "Create Task",
  task_delete_confirmation_header: "Delete Task",
  task_delete_confirmation_message: "Are you sure you want to delete this task?",

  // Priorities
  p1: "P1 - High Priority",
  p2: "P2 - Medium Priority",
  p3: "P3 - Low Priority",
  p4: "P4 - Deferred Priority",

  // Actions
  edit: "Edit",
  create: "Create",
  delete: "Delete",
  yes: "Yes",
  no: "No",

  // General
  loading: "Loading...",
  no_tasks: "There are no tasks here",
  "This field may not be blank.": "This field may not be blank."
};
