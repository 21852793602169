import { useState, useEffect } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import SidebarMenu from "./components/SidebarMenu";
import ListAddSidebar from "./components/list_slider/ListAddSidebar";
import { useTodoList } from './components/states/list';
import Navbar from "./components/Navbar";
import TaskList from "./components/TaskList";
import TaskAddSidebar from "./components/task/TaskAddSlider";
import { ConfirmDialog } from 'primereact/confirmdialog';


function App() {
  const API_ENDPOINT = import.meta.env.VITE_REACT_APP_API_ENDPOINT

  const [tg, setTg] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState([]);

  const setList = useTodoList((state) => state.setList)
  
  const setCurrentList = useTodoList((state) => state.setCurrentList)

  let tgstate = window.Telegram.WebApp;

  useEffect(() => {
    console.log("useTelegram");

    const fetchData = async (dataToSend) => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}/api/user/login/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
          }
        );

        const data = await response.json();
        const token = data.token;

        // Save token to localStorage or secure cookie
        localStorage.setItem("token", token);

        return token;
      } catch (error) {
        setError(error);
      }
    };

    const fetchList = async (token) => {
      setError({ n: 23 });
      try {
        const response = await fetch(
          `${API_ENDPOINT}/api/todo/lists/`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        const statusCode = response.status;
        setError(statusCode);
        if (statusCode === 401) {
          localStorage.removeItem("token");
          setTimeout(initTg, 500);
          return;
        }
        const data = await response.json();

        setList(data);
        setCurrentList(data[0]);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false); // Set loading to false after request completes
      }
    };

    async function initTg() {
      if (
        typeof window !== "undefined" &&
        window.Telegram &&
        window.Telegram.WebApp
      ) {
        console.log("Telegram WebApp is set");
        const tgData = window.Telegram.WebApp;

        tgData.ready();
        tgData.expand();
        console.log(tgData.isVerticalSwipesEnabled);
        tgData.disableVerticalSwipes();

        console.log(tgData.isVerticalSwipesEnabled);

        setTg(tgData);
        tgstate = tgData;

        if (tgData.initData !== "") {
          const storedToken = localStorage.getItem("token");

          if (storedToken) {
            await fetchList(storedToken);
          } else {
            const token = await fetchData({ data: tgData });

            if (token) {
              await fetchList(token);
            }
          }
        }
      } else {
        console.log("Telegram WebApp is undefined, retrying…");
        setTimeout(initTg, 500);
      }
    }

    initTg();
  }, []);

  return (
    <div className="text-center">
      <Navbar />    
      <ConfirmDialog />
      <TaskAddSidebar/>
      <TaskList />
      <SidebarMenu />
      <ListAddSidebar />
    </div>
  );
}

export default App;
