import { create } from "zustand";

export const useTodoList = create((set) => ({
  list: [],
  listEdit: {},
  taskEdit: {},
  currentList: {},
  currentTasks: [],

  addList: (item) =>
    set((state) => {
      const existingIndex = state.list.findIndex((i) => i.id === item.id);
      let updatedList;

      if (existingIndex >= 0) {
        updatedList = [...state.list];
        updatedList[existingIndex] = item;
      } else {
        updatedList = [...state.list, item];
      }

      updatedList.sort((a, b) => a.name.localeCompare(b.name));

      // Check if the added item is the current list and update it if necessary
      const updatedState = { list: updatedList };
      if (state.currentList.id === item.id) {
        updatedState.currentList = item;
      }

      return updatedState;
    }),

  removeList: (listId) =>
    set((state) => {
      const updatedList = state.list.filter((item) => item.id !== listId);

      const updatedState = { list: updatedList };
      if (state.currentList.id === listId) {
        updatedState.currentList = updatedList.length > 0 ? updatedList[0] : {};
      }

      return updatedState;
    }),

  setList: (list) =>
    set(() => ({
      list: list.sort((a, b) => a.name.localeCompare(b.name)),
    })),

  setListEdit: (l) => set(() => ({ listEdit: l })),

  setTaskEdit: (l) => set(() => ({ taskEdit: l })),

  setCurrentList: (l) => set(() => ({ currentList: l })),

  setCurrentTasks: (l) => set(() => ({ currentTasks: l })),

  addTask: (task) =>
    set((state) => {
      return { currentTasks: [task, ...state.currentTasks] };
    }),

  appendTask: (tasks) =>
    set((state) => {
      return { currentTasks: [...state.currentTasks, ...tasks] };
    }),

  removeTask: (taskId) =>
    set((state) => {
      const updatedTasks = state.currentTasks.filter(
        (task) => task.id !== taskId
      );
      return { currentTasks: updatedTasks };
    }),

  editTask: (updatedTask) =>
    set((state) => {
      const updatedTasks = state.currentTasks.map((task) =>
        task.id === updatedTask.id ? updatedTask : task
      );
      return { currentTasks: updatedTasks };
    }),
}));
