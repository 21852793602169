export const RussianTranslation = {
    // Lists
    list: "Списки",
    list_exists: "Список существует",
    error_creating_list: "Ошибка при создании списка",
    error_deleting_list: "Ошибка при удалении списка",
    edit_list: "Редактировать список",
    create_list: "Создать список",
    list_delete_confirmation_message: "Вы хотите удалить этот список со всеми задачами?",
    list_delete_confirmation_header: "Удаление списка",
  
    // Tasks
    task_title_placeholder: "Название задачи",
    select_due_time: "Выберите срок выполнения",
    no_priority: "Без приоритета",
    edit_task: "Редактировать задачу",
    create_task: "Создать задачу",
    task_delete_confirmation_message: "Вы уверены, что хотите удалить эту задачу?",
    task_delete_confirmation_header: "Подтверждение",
  
    // Priorities
    p1: "P1 - Высокий приоритет",
    p2: "P2 - Средний приоритет",
    p3: "P3 - Низкий приоритет",
    p4: "P4 - Отложенный приоритет",
  
    // Actions
    edit: "Редактировать",
    create: "Создать",
    delete: "Удалить",
    yes: "Да",
    no: "Нет",
  
    // General
    loading: "Загрузка...",
    no_tasks: "Здесь нет задач",
    "This field may not be blank.": "Это поле не может быть пустым."
  };
  