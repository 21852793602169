import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";

const MyInputText = ({ id, errors, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-column mb-1 w-full">
      <InputText id={id} className="flex-grow-1" invalid={errors[id] && true} {...props}/>
      {errors[id] && <div className="text-red-600 text-sm mt-1 pl-1">{t(errors[id][0])}</div>}
    </div>
  );
};

export default MyInputText;
