import { Checkbox } from "primereact/checkbox";
import { useState } from "react";
import { useReward } from 'react-rewards';
import { useTodoList } from "./states/list";
import { useAddTaskSlider } from "./states/ui";
import { Tag } from "primereact/tag";

const TaskItem = ({ task }) => {
  const API_ENDPOINT = import.meta.env.VITE_REACT_APP_API_ENDPOINT

  const setTaskToEdit = useTodoList((state) => state.setTaskEdit);
  const showAddTaskSlider = useAddTaskSlider((state) => state.setVisible);

  const [checked, setChecked] = useState(false);
  const { reward, isAnimating } = useReward('reward', 'confetti', {
    spread: 100,
    zIndex: 5,
    startVelocity: 8,
    angle: 320
  });

  const handleCheckboxChange = async (e) => {
    const isChecked = e.checked;
    if (isChecked) {
      reward();
    }
    setChecked(isChecked);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_ENDPOINT}/api/todo/tasks/${task.id}/mark_complete/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ completed: isChecked }),
        }
      );
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "P1": return "text-red-700";
      case "P2": return "text-yellow-600";
      case "P3": return "text-blue-500";
      default: return "";
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const getDueTimeColor = (dueTime) => {
    const dueDate = new Date(dueTime);
    const now = new Date();
    return dueDate < now ? "text-red-700" : "text-500";
  };

  return (
    <div className="flex align-items-start shadow-1 surface-50 border-round mt-2 mb-2 border-200 gap-1 py-1 px-1">
      <div className="flex-none flex mx-1 py-2">
        <Checkbox onChange={handleCheckboxChange} checked={checked} />
      </div>
      <div className="flex-grow-1 text-left py-1 overflow-hidden">
        <div className="flex align-items-center font-bold py-1">
          <div
            className={`flex-grow-1 vertical-align-middle	line-height-2	text-overflow-ellipsis white-space-nowrap overflow-hidden ${checked ? "line-through" : ""}`}
            
          >
            {task.title}
          </div>
          <i
            className="pi pi-pen-to-square text-400 text-sm align-items-center px-2"
            onClick={() => {
              setTaskToEdit(task);
              showAddTaskSlider(true);
            }}
          />
        </div>
        <div className="flex gap-1">
          {task.priority && (
            <div className={`text-500 ${getPriorityColor(task.priority)}`}>
              <i className="pi pi-flag mr-1" style={{ fontSize: ".8rem" }}></i>
              <span className="text-xs">{task.priority}</span>
            </div>
          )}
          {task.priority && task.due_time && (
            <div className="line-height-1 text-400 font-bold vertical-align-middle text-xl">
              ·
            </div>
          )}
          {task.due_time && (
            <div className={`${getDueTimeColor(task.due_time)}`}>
              <i className="pi pi-calendar mr-1" style={{ fontSize: ".8rem" }}></i>
              <span className="text-xs">{formatDate(task.due_time)}</span>
            </div>
          )}
        </div>
        {task.tags && (
          <div className="flex gap-1 mt-1">
            {task.tags.map((tag) => (
              <Tag key={tag.id} value={tag.name} className="font-normal" style={{ fontSize: ".6rem", letterSpacing: ".04rem" }} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskItem;
