export const SpanishTranslation = {
    // Lists
    list: "Listas",
    list_exists: "La lista existe",
    error_creating_list: "Error al crear la lista",
    error_deleting_list: "Error al eliminar la lista",
    edit_list: "Editar lista",
    create_list: "Crear lista",
    list_delete_confirmation_message: "¿Desea eliminar esta lista con todas las tareas?",
    list_delete_confirmation_header: "Eliminar lista",
  
    // Tasks
    task_title_placeholder: "Título de la tarea",
    select_due_time: "Seleccionar hora de vencimiento",
    no_priority: "Sin prioridad",
    edit_task: "Editar tarea",
    create_task: "Crear tarea",
    task_delete_confirmation_message: "¿Está seguro de que desea eliminar esta tarea?",
    task_delete_confirmation_header: "Confirmación",
  
    // Priorities
    p1: "P1 - Alta prioridad",
    p2: "P2 - Prioridad media",
    p3: "P3 - Baja prioridad",
    p4: "P4 - Prioridad diferida",
  
    // Actions
    edit: "Editar",
    create: "Crear",
    delete: "Eliminar",
    yes: "Sí",
    no: "No",
  
    // General
    loading: "Cargando...",
    no_tasks: "No hay tareas aquí",
    "This field may not be blank.": "Este campo no puede estar vacío."
  };
  