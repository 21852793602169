export const ItalianTranslation = {
    // Lists
    list: "Liste",
    list_exists: "La lista esiste",
    error_creating_list: "Errore nella creazione della lista",
    error_deleting_list: "Errore nell'eliminazione della lista",
    edit_list: "Modifica lista",
    create_list: "Crea lista",
    list_delete_confirmation_message: "Vuoi eliminare questa lista con tutti i compiti?",
    list_delete_confirmation_header: "Elimina lista",
  
    // Tasks
    task_title_placeholder: "Titolo del compito",
    select_due_time: "Seleziona l'orario di scadenza",
    no_priority: "Nessuna priorità",
    edit_task: "Modifica compito",
    create_task: "Crea compito",
    task_delete_confirmation_message: "Sei sicuro di voler eliminare questo compito?",
    task_delete_confirmation_header: "Conferma",
  
    // Priorities
    p1: "P1 - Alta Priorità",
    p2: "P2 - Priorità Media",
    p3: "P3 - Bassa Priorità",
    p4: "P4 - Priorità Differita",
  
    // Actions
    edit: "Modifica",
    create: "Crea",
    delete: "Elimina",
    yes: "Sì",
    no: "No",
  
    // General
    loading: "Caricamento...",
    no_tasks: "Non ci sono compiti qui",
    "This field may not be blank.": "Questo campo non può essere vuoto."
  };
  