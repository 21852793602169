export const FrenchTranslation = {
    // Lists
    list: "Listes",
    list_exists: "La liste existe",
    error_creating_list: "Erreur lors de la création de la liste",
    error_deleting_list: "Erreur lors de la suppression de la liste",
    edit_list: "Modifier la liste",
    create_list: "Créer une liste",
    list_delete_confirmation_message: "Voulez-vous supprimer cette liste avec toutes les tâches?",
    list_delete_confirmation_header: "Supprimer la liste",
  
    // Tasks
    task_title_placeholder: "Titre de la tâche",
    select_due_time: "Sélectionner l'heure d'échéance",
    no_priority: "Pas de priorité",
    edit_task: "Modifier la tâche",
    create_task: "Créer une tâche",
    task_delete_confirmation_message: "Êtes-vous sûr de vouloir supprimer cette tâche?",
    task_delete_confirmation_header: "Confirmation",
  
    // Priorities
    p1: "P1 - Haute priorité",
    p2: "P2 - Priorité moyenne",
    p3: "P3 - Basse priorité",
    p4: "P4 - Priorité différée",
  
    // Actions
    edit: "Modifier",
    create: "Créer",
    delete: "Supprimer",
    yes: "Oui",
    no: "Non",
  
    // General
    loading: "Chargement...",
    no_tasks: "Il n'y a pas de tâches ici",
    "This field may not be blank.": "Ce champ ne peut pas être vide."
  };
  